<template>
  <div class="main">
    <div class="index_menu">
      <div
        class="menu_item"
        v-for="(item, i) in menu"
        :key="i"
        :style="{ color: $route.path === item.path ? '#dc84df' : '' }"
        @click="jump(item.path)"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="about_us_content">
      <div class="title">公司简介</div>
      <p>
        沈阳鑫鑫昊网络科技有限公司是一家互联网服务公司，致力于软件开发，软件运营，文艺创作，电影演出等综合服务公司。
      </p>
      <div class="title">App简介</div>
      <p>
        蜜柚派对app是一款语音交友APP，在这里不仅可以享受声控的福利，还能快速匹配适合你的声音。
      </p>
      <p>蜜柚派对 快乐在你左右</p>
      <p>
        这是一款声音交友APP，在这里我们用声音连接人与人，你可以同时与多位小哥哥小姐姐进行连麦互动，找到与你灵魂接近的那个TA。
      </p>
      <p>
        不看脸不尴尬，让你随时随地找到喜欢的声音。[温暖交友]
        在线实时语音，小哥哥小姐姐在线聆听你的倾诉，
      </p>
      <p>用声音治愈你，给你温暖的陪伴</p>
      <p>[语音聊天]</p>
      <p>
        语音连麦交友，通过声音和更多有趣的伙伴交朋友，寻找有趣的灵魂，交友就是这么简单
      </p>
      <p>[社区分享]</p>
      <p>
        在蜜柚派对，分享你的生活瞬间，发现他人的精彩故事。互相了解，互相喜欢，开始你们的故事
      </p>
      <p>[轻松互动]</p>
      <p>心动时刻、语音连麦……多种好玩的互动，让语音交友更有趣味</p>
    </div>
    <div class="index_bottom_company">
      <div
        class="company_item"
        v-for="(item, i) in companyIntroduceList"
        :key="i"
      >
        <div class="name">{{ item.name }}</div>
        <div class="content">{{ item.content }}</div>
      </div>
    </div>
    <div class="bah">辽ICP备2022007659号</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      companyIntroduceList: [
        {
          name: "关于我们",
          content:
            "沈阳鑫鑫昊网络科技有限公司是一家互联网服务公司，致力于软件开发，软件运营，文艺创作，电影演出等综合服务公司",
        },
        {
          name: "公司地址",
          content: "辽宁省沈阳市苏家屯迎春北街86-14号",
        },
        {
          name: "游戏平台",
          content: "Android、IOS、PC",
        },
        {
          name: "关注我们",
          content: "时刻与我们保持联系",
        },
      ],
      menu: [
        {
          name: "首页",
          path: "/",
        },
        {
          name: "关于我们",
          path: "/aboutUs",
        },
        {
          name: "联系我们",
          path: "/contactUs",
        },
      ],
    };
  },
  methods: {
    jump(path) {
      if (this.$route.path === path) {
        return;
      }
      console.log("this.$route.path", this.$route.path, path);
      this.$router.push(path);
    },
  },
};
</script>

<style lang="less" scoped>
.main {
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  position: relative;
  .bah {
    height: 40px;
    line-height: 40px;
    text-align: center;
    background: rgba(0, 0, 0, 0.3);
  }
  .index_bottom_company {
    margin-top: 20px;
    display: flex;
    cursor: pointer;
    padding: 40px 0;
    background: rgba(0, 0, 0, 0.3);
    .company_item {
      width: 25%;
      text-align: center;
      .content {
        margin-top: 10px;
        padding: 0 20%;
        color: rgba(238, 238, 238, 0.8);
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2; /* 控制显示的行数 */
      }
      .name {
        margin-top: 10px;
        font-size: 18px;
        font-weight: 600;
        &:hover {
          color: #dc84df;
        }
      }
    }
  }
  .about_us_content {
    height: 80vh;
    width: 50%;
    margin-left: 24%;
    margin-top: 40px;
    background: rgba(238, 238, 238, 0.8);
    border-radius: 10px;
    padding: 10px;
    color: #000;
    overflow-y: auto;
    p {
      text-indent: 2em;
    }
    .title {
      text-align: center;
      margin: 10px 0;
      font-size: 20px;
      font-weight: 600;
    }
  }
  .index_menu {
    display: flex;
    font-size: 22px;
    font-weight: 600;
    margin: 20px 20px 20px 10%;

    .menu_item {
      letter-spacing: 2px;
      margin-right: 40px;
      cursor: pointer;
      &:hover {
        color: #dc84df;
      }
    }
  }
}
</style>
