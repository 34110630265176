<template>
  <div class="main">
    <div class="index_menu">
      <div
        class="menu_item"
        v-for="(item, i) in menu"
        :key="i"
        :style="{ color: $route.path === item.path ? '#dc84df' : '' }"
        @click="jump(item.path)"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="contact">contact</div>
    <div class="us">us</div>
    <div class="contact_us">
      <div class="us_item" v-for="(item, i) in contactList" :key="i">
        <div class="icon">
          <img :src="item.icon" alt="" />
        </div>
        <div class="name">{{ item.name }}</div>
      </div>
    </div>
    <div class="index_bottom_company">
      <div
        class="company_item"
        v-for="(item, i) in companyIntroduceList"
        :key="i"
      >
        <div class="name">{{ item.name }}</div>
        <div class="content">{{ item.content }}</div>
      </div>
    </div>
    <div class="bah">辽ICP备2022007659号</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      contactList: [
        {
          name: "18842598647",
          icon: require("@/assets/phone.png"),
        },
        {
          name: "辽宁省沈阳市苏家屯迎春北街86-14号",
          icon: require("@/assets/address.png"),
        },
        {
          name: "2861424272@qq.com",
          icon: require("@/assets/email.png"),
        },
      ],
      companyIntroduceList: [
        {
          name: "关于我们",
          content:
            "沈阳鑫鑫昊网络科技有限公司是一家互联网服务公司，致力于软件开发，软件运营，文艺创作，电影演出等综合服务公司",
        },
        {
          name: "公司地址",
          content: "辽宁省沈阳市苏家屯迎春北街86-14号",
        },
        {
          name: "游戏平台",
          content: "Android、IOS、PC",
        },
        {
          name: "关注我们",
          content: "时刻与我们保持联系",
        },
      ],
      menu: [
        {
          name: "首页",
          path: "/",
        },
        {
          name: "关于我们",
          path: "/aboutUs",
        },
        {
          name: "联系我们",
          path: "/contactUs",
        },
      ],
    };
  },
  methods: {
    jump(path) {
      if (this.$route.path === path) {
        return;
      }
      console.log("this.$route.path", this.$route.path, path);
      this.$router.push(path);
    },
  },
};
</script>

<style lang="less" scoped>
.main {
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  position: relative;
  .bah {
    height: 40px;
    line-height: 40px;
    text-align: center;
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    bottom: 0px;
    width: 100%;
  }
  .contact_us {
    display: flex;
    position: absolute;
    top: 50%;
    cursor: pointer;
    .us_item {
      width: 300px;
      text-align: center;
    }
  }
  .contact {
    position: absolute;
    font-size: 120px;
    letter-spacing: 2px;
    left: 10%;
  }
  .us {
    position: absolute;
    font-size: 120px;
    letter-spacing: 2px;
    left: 28%;
    top: 20%;
  }
  .index_bottom_company {
    width: 100vw;
    position: absolute;
    bottom: 40px;
    margin-top: 20px;
    display: flex;
    cursor: pointer;
    padding: 40px 0;
    background: rgba(0, 0, 0, 0.3);
    .company_item {
      width: 25%;
      text-align: center;
      .content {
        margin-top: 10px;
        padding: 0 20%;
        color: rgba(238, 238, 238, 0.8);
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2; /* 控制显示的行数 */
      }
      .name {
        margin-top: 10px;
        font-size: 18px;
        font-weight: 600;
        &:hover {
          color: #dc84df;
        }
      }
    }
  }
  .index_menu {
    display: flex;
    font-size: 22px;
    font-weight: 600;
    margin: 20px 20px 20px 10%;

    .menu_item {
      letter-spacing: 2px;
      margin-right: 40px;
      cursor: pointer;
      &:hover {
        color: #dc84df;
      }
    }
  }
}
</style>
