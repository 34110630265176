import Vue from "vue";
import VueRouter from "vue-router";
import index from "../views/index.vue";
import aboutUs from "../views/aboutUs.vue";
import contactUs from "../views/contactUs.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "index",
    component: index,
  },
  {
    path: "/aboutUs",
    name: "aboutUs",
    component: aboutUs,
  },
  {
    path: "/contactUs",
    name: "contactUs",
    component: contactUs,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
