<template>
  <div class="main">
    <div class="main_name">蜜柚party</div>
    <div class="index_menu">
      <div
        class="menu_item"
        v-for="(item, i) in menu"
        :key="i"
        :style="{ color: $route.path === item.path ? '#dc84df' : '' }"
        @click="jump(item.path)"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="index_img">
      <div class="img_item" v-for="(item, i) in indexImgList" :key="i">
        <img :src="item" alt="" />
      </div>
    </div>
    <div class="index_bottom_down">
      <div
        class="down_item"
        v-for="(item, i) in downAppList"
        :key="i"
        @click="openPc(item)"
      >
        <div class="icon">
          <img :src="item.icon" alt="" />
        </div>
        <div class="name">{{ item.name }}</div>
      </div>
    </div>
    <!-- <div class="hr"></div> -->
    <div class="index_bottom_company">
      <div
        class="company_item"
        v-for="(item, i) in companyIntroduceList"
        :key="i"
      >
        <div class="name">{{ item.name }}</div>
        <div class="content">{{ item.content }}</div>
      </div>
    </div>
    <div class="bah">辽ICP备2022007659号</div>
    <img class="welcome" src="@/assets/WeLcome.png" alt="" />
    <img class="to" src="@/assets/To.png" alt="" />
    <el-dialog :title="donwTitle" :visible.sync="dialogVisible" width="30%">
      <div class="down_main">
        <div class="down_title">请扫描二维码进行下载</div>
        <div class="down_img">
          <img src="@/assets/down.png" alt="" />
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      donwTitle: "",
      dialogVisible: false,
      companyIntroduceList: [
        {
          name: "关于我们",
          content:
            "沈阳鑫鑫昊网络科技有限公司是一家互联网服务公司，致力于软件开发，软件运营，文艺创作，电影演出等综合服务公司",
        },
        {
          name: "公司地址",
          content: "辽宁省沈阳市苏家屯迎春北街86-14号",
        },
        {
          name: "游戏平台",
          content: "Android、IOS",
        },
        {
          name: "关注我们",
          content: "时刻与我们保持联系",
        },
      ],
      downAppList: [
        {
          name: "安卓下载",
          icon: require("@/assets/az.png"),
        },
        {
          name: "ios下载",
          icon: require("@/assets/iOS.png"),
        },
        // {
        //   name: "PC下载",
        //   icon: require("@/assets/pc.png"),
        // },
        // {
        //   name: "打开网页",
        //   icon: require("@/assets/h5.png"),
        // },
      ],
      indexImgList: [
        require("@/assets/1.png"),
        require("@/assets/2.png"),
        require("@/assets/3.png"),
        require("@/assets/4.png"),
      ],
      menu: [
        {
          name: "首页",
          path: "/",
        },
        {
          name: "关于我们",
          path: "/aboutUs",
        },
        {
          name: "联系我们",
          path: "/contactUs",
        },
      ],
    };
  },
  methods: {
    openPc(row) {
      this.donwTitle = row.name;
      this.dialogVisible = true;
      if (row.name === "打开网页") {
        // window.open("https://pc.yinquparty.com");
      }
    },
    jump(path) {
      if (this.$route.path === path) {
        return;
      }
      console.log("this.$route.path", this.$route.path, path);
      this.$router.push(path);
    },
  },
  mounted() {
    console.log("route", this.$route);
  },
};
</script>

<style lang="less" scoped>
.main {
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  position: relative;
  .down_main {
    text-align: center;
    .down_img {
      margin-top: 10px;
      display: flex;
      justify-content: center;
    }
  }
  .bah {
    height: 40px;
    line-height: 40px;
    text-align: center;
    background: rgba(0, 0, 0, 0.3);
  }
  .main_name {
    position: absolute;
    font-size: 140px;
    top: 35%;
    left: 20%;
    color: #dc84df;
  }
  .index_bottom_company {
    display: flex;
    cursor: pointer;
    padding: 40px 0;
    background: rgba(0, 0, 0, 0.3);
    .company_item {
      width: 25%;
      text-align: center;
      .content {
        margin-top: 10px;
        padding: 0 20%;
        color: rgba(238, 238, 238, 0.8);
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2; /* 控制显示的行数 */
      }
      .name {
        margin-top: 10px;
        font-size: 18px;
        font-weight: 600;
        &:hover {
          color: #dc84df;
        }
      }
    }
  }
  .hr {
    height: 2px;
    background: #eee;
  }
  .index_bottom_down {
    display: flex;
    margin: 40px 0;
    cursor: pointer;
    .down_item {
      width: calc(100% / 2);
      text-align: center;
      .name {
        margin-top: 10px;
        &:hover {
          color: #dc84df;
        }
      }
      .icon {
        img {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
  .welcome {
    position: absolute;
    top: 120px;
    left: 200px;
  }
  .to {
    position: absolute;
    top: 260px;
    left: 800px;
  }
  .index_img {
    display: flex;
    margin: 450px 0 0 200px;
    .img_item {
      margin-right: 30px;
      img {
        width: 200px;
        height: 430px;
      }
    }
  }
  .index_menu {
    display: flex;
    font-size: 22px;
    font-weight: 600;
    margin: 20px 20px 20px 10%;

    .menu_item {
      letter-spacing: 2px;
      margin-right: 40px;
      cursor: pointer;
      &:hover {
        color: #dc84df;
      }
    }
  }
}
</style>
